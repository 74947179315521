"use client";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import Menu from "./Menu";
import menuItems from "../data/menu.json";

const Header = () => {
  return (
    <div className="flex flex-col pt-2 md:pb-6 items-center justify-center text-xs text-custom-primary font-medium">
      <div className="flex w-full justify-between items-center relative px-6">
        <p className="font-semibold text-lg">Autowagen</p>
        <a
          className="ml-2"
          href="https://www.heycarter.co.za/"
          rel="noreferrer"
          target="_blank"
        >
          <img
            className="h-9 -mb-2"
            src="/images/carter logo.png"
            alt=""
            style={{ objectFit: "contain" }}
          />
        </a>
        <div className="hidden md:flex  pr-8 uppercase font-semibold text-xs gap-4 tracking-wide">
          {menuItems.map(({ title, route }) => {
            if (title.toUpperCase() === "VEHICLES") {
              return (
                <HashLink key={title} to={route}>
                  Vehicles
                </HashLink>
              );
            } else {
              return (
                <Link key={title} to={route}>
                  {title}
                </Link>
              );
            }
          })}
        </div>
      </div>
      <div className="flex items-center w-full pt-4">
        <div className="h-[2px] flex-grow bg-custom-primary"></div>
        <div className="bg-white px-4">
          <Link to="/">
            <div className="flex justify-left relative">
              <div>
                <img
                  className="h-8 md:h-12"
                  src="/images/brand-logo.svg"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </Link>
        </div>
        <div className="h-[2px] flex-grow md:w-64 md:flex-grow-0 bg-custom-primary"></div>
      </div>
    </div>
  );
};

const Nav = ({ menuOpen, toggleMenu }) => {
  return (
    <div className="px-4 pb-2 pr-6 flex justify-end items-center">
      <div className="md:hidden flex">
        {!menuOpen && (
          <button
            className="flex items-center justify-center h-10 w-10"
            onClick={toggleMenu}
          >
            <Bars3Icon height={24} width={24} />
          </button>
        )}
        {menuOpen && (
          <button
            className="flex items-center justify-center h-10 w-10"
            onClick={toggleMenu}
          >
            <XMarkIcon height={24} width={24} />
          </button>
        )}
      </div>

      <Menu menuOpen={menuOpen} />
    </div>
  );
};

const NavBar = ({ ...company }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <Header {...company} />
      <Nav
        {...company}
        menuOpen={menuOpen}
        toggleMenu={() => setMenuOpen((prev) => !prev)}
      />
    </div>
  );
};

export default NavBar;
