"use client";

const ImageX = ({ src, ...props }) => {
  return (
    <div className="bg-gray-100 relative w-full h-full">
      <img {...props} src={src} alt="..." style={{ objectFit: "cover" }} />
    </div>
  );
};

export default ImageX;
