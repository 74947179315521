import React from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import requests from "../../services/api/requests";

import Chip from "../../components/Chip";
import Content from "./Content";
import LeadForm from "../../components/LeadForm";
import Carousel from "../../components/Carousel";
import Loading from "./loading";
import ScrollTopButton from "../../components/ScrollTopButton";

import { capitalizeStr } from "../../utilities/capitalizeStr";
import leadFormType from "../../data/leadFormType";

const Header = ({ range, model }) => {
  const pricing = model?.pricing;
  const priceInclVAT = pricing?.listPriceIncl;
  const monthlyEstimate = pricing?.estimatedMonthly ?? 0;

  const fuel = model?.specifications?.engine?.find(
    (engine) => engine?.key?.toLowerCase() === "fuel type"
  )?.value;

  const transmission = model?.specifications?.drive?.find(
    (drive) => drive?.key?.toLowerCase() === "gearshift"
  )?.value;

  const images = model?.pictures?.map(({ pictureWebPURL }) => pictureWebPURL);

  return (
    <div>
      <Carousel items={images} />
      <div className="p-4">
        <div>
          <h3 className="text-lg font-semibold">
            {`Volkswagen ${range?.rangeName}`}
          </h3>
          <p className="text-sm font-medium">{model?.modelName}</p>
          {fuel && transmission && (
            <div className="flex gap-2 mt-3">
              {fuel && <Chip label={capitalizeStr(fuel)} />}
              {transmission && <Chip label={capitalizeStr(transmission)} />}
            </div>
          )}
          <div className="p-3 mt-6 border border-gray-200 rounded-sm">
            <h4 className="text-[11px] uppercase font-semibold">Pricing</h4>
            <h3 className="my-3 text-xl">
              <span className="font-semibold">{`R ${parseInt(
                monthlyEstimate
              )?.toLocaleString("en-ZA")}`}</span>{" "}
              <span className="text-base">pm</span>
              <br className="py-2" />
              <span className="text-sm font-medium">
                <span className="font-normal">or</span>{" "}
                <span>{`R ${priceInclVAT?.toLocaleString("en-ZA")}`}</span>{" "}
                <span className="text-xs">cash price</span>
                <span className="text-red-500">*</span>
              </span>
            </h3>
            <p className="text-[10px]">
              <span className="font-bold text-red-500">*</span>{" "}
              <span>Prices include VAT. Terms & Conditions apply.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page = () => {
  const { data } = useLoaderData();

  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data} errorElement={<p>Error loading car model</p>}>
        {(data) => (
          <div className="relative flex flex-col lg:flex-row">
            <div className="lg:sticky lg:top-24 lg:h-full lg:w-[360px]">
              <Header {...data} />
            </div>

            <div className="flex-grow pb-8 border-l border-r">
              <Content {...data} />
            </div>

            <div className="bg-[#fafafa] flex flex-col 2xl:h-screen md:max-w-[320px] px-4 py-4 lg:sticky lg:top-24">
              <div className="rounded-lg  xl:h-full">
                <div className="mb-8">
                  <h1 className="text-3xl font-extrabold">Enquire</h1>
                  <p className="mt-2 text-xs font-medium">
                    If you are interested in this vehicle and would like our
                    team to contact you, please fill out your contact
                    information below
                  </p>
                </div>
                <div className="flex-grow">
                  <LeadForm carData={data} type={leadFormType.lead} />
                </div>
              </div>
              <div className="px-0 pt-6 md:hidden ">
                <ScrollTopButton />
              </div>
            </div>
          </div>
        )}
      </Await>
    </React.Suspense>
  );
};

export default Page;

export const fetchCar = ({ params }) => {
  const modelID = params.modelID;
  const data = requests.getCar(modelID);
  return defer({
    data,
  });
};
