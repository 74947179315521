import React from "react";
import requests from "../../services/api/requests";
import { Await, defer, useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";

// import Accordion from "../../components/Accordion";
import Chip from "../../components/Chip";
import Loading from "./loading";

import {
  ChevronRightIcon,
  // WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

import { capitalizeStr } from "../../utilities/capitalizeStr";

const Card = ({
  rangeName,
  picture,
  brand,
  model,
  fuel,
  transmission,
  price,
}) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-sm w-[300px] hover:drop-shadow">
      <div className=" bg-gray-200 relative w-[300px] h-[150px]">
        <img src={picture} alt="" style={{ objectFit: "cover" }} />
      </div>
      <div className="px-4 py-4">
        <h3 className="text-xs">
          <span>{brand ?? "Volkswagen"}</span> <span>{rangeName}</span>
        </h3>
        <p className="text-sm font-medium">
          <span>{model}</span>
        </p>
        <div className="flex gap-2 mt-3">
          <Chip label={capitalizeStr(fuel)} />
          <Chip label={capitalizeStr(transmission)} />
        </div>
        <p className="mt-3 text-sm">
          <span>Starting from</span>{" "}
          <span className="font-bold">{`R ${parseInt(price).toLocaleString(
            "en-ZA"
          )}`}</span>{" "}
          <span className="font-bold text-red-500">*</span>
        </p>
        <div className="flex items-center mt-4 -ml-1 text-xs font-medium">
          <div className="flex items-center justify-center w-3 h-3 mr-1 font-medium">
            <ChevronRightIcon />
          </div>
          <span>Show more</span>
        </div>
      </div>
    </div>
  );
};

// const Metric = ({ value, unit, label }) => {
//   return (
//     <div>
//       <p className="inline-block">
//         <span className="text-2xl">{value}</span>{" "}
//         <span className="text-sm">{unit}</span>
//       </p>
//       <p className="text-[10px]">{label}</p>
//     </div>
//   );
// };

// const Property = ({ label, value }) => {
//   return (
//     <div className="flex justify-between text-[13px] py-2">
//       <p>{label}</p>
//       <p className="font-medium">{value}</p>
//     </div>
//   );
// };

// const Sidebar = ({ data, fuel, transmission }) => {
//   console.log(data);
//   const specifications = data?.specifications;

//   //#region performance
//   const performance = specifications?.performance;
//   const zeroToHundred =
//     performance?.find(({ key }) => key?.toLowerCase().includes("acceleration"))
//       ?.value ?? 0;

//   const maximumPower =
//     performance?.find(({ key }) => key?.toLowerCase().includes("power maximum"))
//       ?.value ?? 0;

//   const maximumTorque =
//     performance?.find(({ key }) =>
//       key?.toLowerCase().includes("torque maximum")
//     )?.value ?? 0;
//   //#endregion

//   //#region Fuel Economy
//   const fuelEconomy = specifications?.fuelEconomy;
//   const fuelConsumption =
//     fuelEconomy?.find(({ key }) =>
//       key?.toLowerCase().includes("fuel consumption")
//     )?.value ?? "Unknown";

//   const emissions =
//     fuelEconomy?.find(({ key }) => key?.toLowerCase().includes("emissions"))
//       ?.value ?? "Unknown";
//   //#endregion

//   //#region dimensions
//   const dimensions = specifications?.dimensions;
//   const doors =
//     dimensions?.find(({ key }) => key?.toLowerCase().includes("doors"))
//       ?.value ?? "Unknown";

//   const seats =
//     dimensions?.find(({ key }) => key?.toLowerCase().includes("seats"))
//       ?.value ?? "Unknown";

//   const fuelTankCapacity =
//     dimensions?.find(({ key }) =>
//       key?.toLowerCase().includes("fuel tank capacity")
//     )?.value ?? "Unknown";
//   //#endregion

//   //#region
//   const maintenance = specifications?.maintenance;
//   const warrantyYears = maintenance?.find(({ key }) =>
//     key?.toLowerCase().includes("warranty time")
//   )?.value;

//   const warrantyDistance = maintenance?.find(({ key }) =>
//     key?.toLowerCase().includes("warranty distance")
//   )?.value;
//   //#endregion

//   return (
//     <div className="w-full md:w-[325px]">
//       <div>
//         <Accordion label="Perfomance">
//           <div className="py-4">
//             <div className="flex items-center gap-4 pb-6">
//               <Metric
//                 value={parseFloat(zeroToHundred)}
//                 unit="sec"
//                 label="0-100 km/h"
//               />
//               <div className="bg-gray-300 h-6 w-[1px]"></div>
//               <Metric
//                 value={parseFloat(maximumPower)}
//                 unit="kW"
//                 label="Maximum power"
//               />
//               <div className="bg-gray-300 h-6 w-[1px]"></div>
//               <Metric
//                 value={parseFloat(maximumTorque)}
//                 unit="Nm"
//                 label="Maximum torque"
//               />
//             </div>
//             <p className="text-[10px]">
//               <span className="font-bold text-red-500">*</span>{" "}
//               <span>Provided values are only average estimates. E & OE.</span>
//             </p>
//           </div>
//         </Accordion>
//       </div>

//       <div className="mt-2">
//         <Accordion label="Specifications">
//           <div>
//             <div className="py-4 border-b">
//               <Property label="Fuel type" value={fuel ?? "Unknown"} />
//               <Property label="Fuel capacity" value={fuelTankCapacity} />
//               <Property label="Transmission" value={transmission} />
//               <Property label="Doors" value={doors} />
//               <Property label="Seats (quantity)" value={seats} />
//               <Property label="Fuel consumption(avg)" value={fuelConsumption} />
//               <Property label="CO2 emissions(avg)" value={emissions} />
//             </div>
//             {warrantyDistance && warrantyYears && (
//               <div>
//                 <div className="flex items-center">
//                   <div className="flex items-center justify-center w-4 h-4 mr-2">
//                     <WrenchScrewdriverIcon />
//                   </div>
//                   <p className="text-[13px] py-3">
//                     <span>{`${warrantyYears}${
//                       warrantyDistance &&
//                       `/${
//                         parseFloat(warrantyDistance)?.toLocaleString("en-ZA") +
//                         " km"
//                       } stardard warranty`
//                     }`}</span>{" "}
//                     <span className="font-bold text-red-500">*</span>
//                   </p>
//                 </div>
//               </div>
//             )}
//           </div>
//         </Accordion>
//       </div>
//     </div>
//   );
// };

const Page = () => {
  const { models, car } = useLoaderData();

  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={car}
        errorElement={<p className="px-6 py-4">Error loading models!</p>}
      >
        {(car) => {
          // Sort by price ascending
          const data = models;

          const picture =
            car?.model?.pictures[0]?.pictureWebPURL ??
            car?.model?.pictures[0]?.pictureNormalURL;

          // let transmissionSupport = data?.map(({ transmission }) =>
          //   capitalizeStr(transmission)
          // );
          // transmissionSupport = new Set(transmissionSupport);
          // transmissionSupport = Array.from(transmissionSupport);
          // transmissionSupport = transmissionSupport.join(", ");

          // let fuelSupport = data?.map(({ fuel }) => capitalizeStr(fuel));
          // fuelSupport = new Set(fuelSupport);
          // fuelSupport = Array.from(fuelSupport);
          // fuelSupport = fuelSupport.join(", ");

          return (
            <div className="w-full">
              <div className="relative flex flex-col ">
                <div className="bg-white px-6 md:px-6 py-4 md:sticky h-[100%]">
                  <div className="flex flex-col justify-center py-3 mb-0">
                    <h3 className="text-lg font-semibold">
                      Volkswagen {capitalizeStr(car?.range?.rangeName)}
                    </h3>
                    <p className="text-sm font-medium">
                      Available Models {`(${data?.length ?? 0})`}
                    </p>
                  </div>
                  {/* <Sidebar
                    data={car?.model}
                    fuel={fuelSupport}
                    transmission={transmissionSupport}
                  /> */}
                </div>
                <div className="flex flex-wrap items-center justify-center flex-grow gap-6 px-6 py-6 bg-gray-50 md:justify-start md:items-start rounded-t-xl ">
                  {data?.map((item, i) => (
                    <Link key={i} to={`/range/model/${item?.model_ID}`}>
                      <Card
                        rangeName={car?.range?.rangeName}
                        picture={picture}
                        {...item}
                      />
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-center py-8">
                <p className="text-[10px]">
                  <span className="font-bold text-red-500">*</span>{" "}
                  <span>
                    Prices may vary from quoted amount. Terms & Conditions
                    apply.
                  </span>
                </p>
              </div>
            </div>
          );
        }}
      </Await>
    </React.Suspense>
  );
};

export default Page;

export const fetchModels = async ({ params }) => {
  const rangeName = params.rangeName;

  const models = await requests.getModels(rangeName);

  models?.sort((a, b) => {
    if (a.price < b.price) {
      return -1;
    } else if (a.price > b.price) {
      return 1;
    } else return 0;
  });

  const car = requests.getCar(models[models?.length - 1]?.model_ID);
  return defer({
    models,
    car,
  });
};
