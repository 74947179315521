import React from "react";
import { useLoaderData } from "react-router-dom";
import requests from "../../services/api/requests";
import Card from "./Card";

const Page = () => {
  const dealerships = useLoaderData();

  return (
    <div className="flex flex-col w-full py-3">
      <div className="flex flex-col justify-center px-6 mb-6">
        <h3 className="text-lg font-semibold">Dealerships</h3>
        <p className="text-sm font-medium">
          Volkswagen dealerships {`(${dealerships?.length ?? ""})`}
        </p>
      </div>
      <ul className="flex flex-wrap w-full gap-6 px-6 py-6 bg-gray-100">
        {dealerships?.map((dealership, i) => (
          <div key={i} className="md:w-[350px] w-full">
            <Card {...dealership} />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Page;

export const fetchDealerships = async () => {
  const resp = await requests.getDealerships();
  return resp ?? [];
};
